<!-- 企业订单 -->
<template>
  <div>
    <el-card> 企业订单 </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>
<style lang='scss' scoped>
</style>